// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-build-mdx": () => import("./../../../src/pages/build.mdx" /* webpackChunkName: "component---src-pages-build-mdx" */),
  "component---src-pages-electronics-mdx": () => import("./../../../src/pages/electronics.mdx" /* webpackChunkName: "component---src-pages-electronics-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-software-mdx": () => import("./../../../src/pages/software.mdx" /* webpackChunkName: "component---src-pages-software-mdx" */),
  "component---src-pages-stls-mdx": () => import("./../../../src/pages/stls.mdx" /* webpackChunkName: "component---src-pages-stls-mdx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

