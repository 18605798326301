import React from "react"

import { bom } from "./bom"
import Image from "../components/image"

const PartList = () => (<div>
  {bom.map(entry => (<div className='bom-entry'>
    <div className='bom-title'><a href={entry.link}>{entry.name}</a></div>
    <div className='bom-details'>
      <div className='bom-photo'>
        <Image src={entry.photo} />
      </div>
      <div className='bom-description'>
        {entry.description}
      </div>
    </div>
  </div>))}
</div>)

export default PartList