interface Entry {
  name: string
  description: string
  photo: string
  link?: string
}

export const bom: Entry[] = [
  {
    name: 'Microswitch with roller X 2',
    description: 'Rollers really help with the sensitivity of the bumper. The springs are also strong enough to push the bumper forward.',
    photo: 'parts/microswitch.jpg',
    link: 'https://www.ebay.com.au/itm/10x-Micro-Roller-Lever-Arm-Open-Close-Limit-Switch-Kw11-B-PCB-Microswitch-AU/124233856875'
  },
  {
    name: 'JGA25-370 (130RPM, 6V) DC motor with wheels X 2',
    description: 'I\'ve experimented with different gear ratios and 130RPM/46 reduction ratio seems to be a good spot. The robot can comfortable travel at 0.3m/s speed with high enough torque.',
    photo: 'parts/DiY-Arduino-Geared-Motors-6V-12V-24V-DC-Gear-Motor-With-Encoder-and-65mm-Wheel-Coupling.jpg',
    link: 'https://www.aliexpress.com/item/32946445527.html'
  },
  {
    name: 'Arduino Nano',
    description: 'Arduino Nano is more than enough for this as it\'s mainly used as a bridge between RPi and the motors.',
    photo: 'parts/arduino_nano.jpg',
    link: 'https://www.aliexpress.com/item/4000587268145.html'
  },
  {
    name: 'TB6612FNG motor driver',
    description: 'Dual Motor Driver 1A. Way smaller and much more efficient than good old l298n driver.',
    photo: 'parts/TB6612FNG.jpg',
    link: 'https://www.aliexpress.com/item/32723927407.html'
  },
  {
    name: 'RPLidar A1',
    description: 'Slamtec RPLidar A1.',
    photo: 'parts/rplidar.jpg',
    link: 'https://www.aliexpress.com/item/32952575835.html'
  },
  {
    name: 'Raspberry Pi 3 Model B',
    description: 'Any Raspberry Pi supporting arm64 architecture (ROS only packages new releases for arm64) would work.',
    photo: 'parts/rpi3.webp',
    link: 'https://www.raspberrypi.org/products/raspberry-pi-3-model-b/'
  },
  {
    name: 'Logic Level Converter',
    description: '',
    photo: 'parts/IIC-I2C-Logic-Level-Converter-Bi-Directional-Module-5V-to-3-3V-For-Arduino.jpg',
    link: 'https://www.aliexpress.com/item/4000587260340.html'
  },
  {
    name: 'EasyAcc PB10000CF Power Bank',
    description: 'Probably the trickiest part. If you can\'t get it, you\'d have to modify OpenScad files to fit another one',
    photo: 'parts/powerbank.jpg',
    link: 'https://www.easyacc.com/en/615-easyacc-2nd-gen-brilliant-10000mah-power-bank.html'
  },
  {
    name: 'Caster ball',
    description: 'height: 15mm, width: 22mm, ball diameter: 12mm',
    photo: 'parts/caster_ball.jpg',
    link: 'https://www.aliexpress.com/item/32723423522.html'
  }
]