import React from "react"
import stls from './stls.json';

const StlList = () => (<div>
  {stls.map(stl => (<div className="stl-entry">
    <div className='stl-title'><a href={`https://raw.githubusercontent.com/Leonti/avobot-body/master/stl/${stl.name}.stl`}>{stl.title}</a></div>
    <div className='stl-viewer'>
      <iframe height='420' width='620' frameborder='0' 
        src={`https://render.githubusercontent.com/view/3d?url=https://raw.githubusercontent.com/Leonti/avobot-body/master/stl/${stl.name}.stl`} 
        title={`${stl.name}.stl`} />
    </div>
    <div className='stl-description'>{stl.desc}</div>
  </div>))}
</div>)

export default StlList